import { signInWithRedirect } from 'firebase/auth';
import { auth, provider } from '.';
import './App.css';
import { Stream } from './component/Stream';

function App() {

  const signIn = () => {
    signInWithRedirect(auth, provider).catch((err) => alert(err.message));
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <img src='/images/round-robin.svg' className="App-logo" alt="logo" />
        <p>
          The Round Robin.
        </p>
        <button 
          onClick={signIn}
          style={{
          background: 'linear-gradient(90deg, #667eea, #764ba2)',
          border: 'none',
          borderRadius: '30px',
          color: 'white',
          fontSize: '16px',
          fontWeight: '600',
          padding: '12px 24px',
          boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
          cursor: 'pointer',
          transition: 'all 0.3s ease'
          }}
          onMouseOver={(e) => {
            e.target.style.background = 'linear-gradient(90deg, #764ba2, #667eea)';
          }}
          onMouseOut={(e) => {
            e.target.style.background = 'linear-gradient(90deg, #667eea, #764ba2)';
          }}
          >Sign In
        </button>
      </header>
      {/* <Stream/> */}
    </div>
  );
}

export default App;